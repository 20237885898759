/* This is to add a border and margin around the photos */
.project-photos{
    margin:5px;
    border: 3px solid black;
}
.project-photos:hover{
    transform: scale(1.4);
}
.this-project-title{
    margin-top:30px;
}
.tech-list li{
    margin: 8px;
    display: inline;
    color: red;
    font-size:x-large;
}
/* this is to format the text of the description */
.project-description{
    width:65%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 650px){
    .project-photos{
        width:100vw;
        margin-left: 0;
    }
}
@media screen and (max-width: 800px){
    .tech-list li{
        display: list-item;
        list-style-position: inside;
        font-size:medium;
    }
}