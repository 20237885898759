@media only screen and (max-device-width: 800px) {
    .show-mobile {
      display: block;
    }
    .hide-mobile {
      display: none;
    }
  }

  @media only screen and (min-device-width: 801px) {
    .show-mobile {
      display: none;
    }
    .hide-mobile {
      display: block;
    }
  }