.grid-container{
    margin-top: 25px;
    display: grid;
    grid-template-rows: auto auto auto;
    width: 95%;
}
.home-title{
    margin-bottom: 25px;
}
.home-container{
    margin-top: 25px;
    display: grid;
    grid-template-columns: 48% 48%;
    width: 90%;
}
.about-component{
    width: 45%;
    margin-left: 92px;
    float: left;
    white-space: normal;
    display: flex;
    height: 450px;
}
.current-projects-component{
    width: 40%;
    float: right;
    white-space: normal;
    display:flex;
    margin-right: 88px;
    height: 450px;
}
.languages-info{
    margin-top: 25px;
    width: 100%;
    margin-bottom: 50px;
}
/* for larger mid size screens */
@media screen and (min-width: 981px) and (max-width:1265px){
    .grid-conainer{
        width: 100vw;
    }
    .home-container{
        width:100vw;
        grid-template-columns: none;
    }
    .about-component{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        display: block;
        height: 350px;
    }
    .current-projects-component{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
        margin-bottom: 10px;
        height: 350px;
        display: block;
    }

}
/* for small and mid size screens */
@media screen and (max-width: 980px){
    .grid-container{
        width: 100vw;
    }
    .home-container{
        width: 100vw;
    }
   .about-component{
       width:100vw;
       display: block;
       margin-left: 0;
       margin-bottom:50px;
       height: 280px;
   }
   .current-projects-component{
       width: 100vw;
       display: block;
       margin-top: 30px;
       height: 400px;
       /* margin-bottom: 80px; */
   }
   .languages-info{
       width: 100vw;
       display:block;
       margin-left: 0;

   }   
}
/* for small screens */
@media screen and (max-width: 500px){
    .about-component{
        height:480px;
    }
    .current-projects-component{
        height: 540px;
    }
}