.resume-container{
    width: 100%;
    height: 90vh;
}
.resume-text-area{
    background-color: #CEE8F7;
    width: 40%;
    margin: auto;
    height: 40px;
    margin-top: 50px;
}
.resume-title{
    color: #fff
}