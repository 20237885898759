.languages-container{
    margin-top: 20px;
    grid-template-columns: 32% 32% 32%;
    display: grid;
    width:100%;
}
.frequently-used{
    display:inline-block;
    list-style-position: inside;
}
.tech-used{
    display:inline-block;
    list-style-position: inside;
}
.soft-skills{
    display: inline-block;
    list-style-position: inside;
}
.languages-container-mobile{
    display: none;
}

@media screen and (max-width: 980px){
    .languages-container{
        display:none;
    }
    .languages-container-mobile{
        display:block
    }
    .frequently-used{
        width: 100vw;
    }
    .tech-used{
        display: block;
        width: 100vw;
    }
    .soft-skills{
        display: block;
        width: 100vw;
    }
}