.label-contact{
    font-size: large;
    color:red;
    margin-right: 5px;
}
.contact-photo{
    margin-left: auto;
    margin-right: auto;
    width: 70%; 
    /* background-image: url('https://i.imgur.com/EQuwGo2.png');
    background-size: cover;
    background-repeat: no-repeat; */
}

.contact-container{
    width:100%;
}
.contact-text{
    margin-top: 25px;
}