.landing-page-body{
    background-color: black;
    color: white;
    font-family: 'Playfair Display', serif;
}
.emphasize{
    color: red;
}
@media screen and (max-width: 600px){
    .Box{
        width:90%;
    }
}

