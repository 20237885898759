.about-me{
    margin-top: 25px;
}
.about-me-container{
    /* margin-left: 2%;
    width: 45%; */
    /* display: inline-flex; */
    display: grid;
    grid-template-columns: 45% 45%;
}
.my-self-image{
    border-radius: 16px;
    display: block;
    border: 5px solid black;
}
.about-me-info{
    display:block;
}


/* For Smaller Screens */
@media screen and (max-width: 500px){
    .about-me-container{
        display: block;
    }
    .my-photo{
        width: 100%;
    }
    .about-me-info{
        width: 100%;
        display:block
    }
}

